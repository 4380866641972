<template>
  <AtomsGlobalOverlay v-if="loader.show" :is-visible="loader.show" :customZIndex="2002">
    <div>
      <div>
        <AtomsImagesImage alt="SnubníPrsteny4u.eu" src="/img/logo.png" class="max-h-[50px] sm:max-w-[300px] xl:max-w-[420px] xl:max-h-[100px]" />
      </div>
      <div class="lds-heart">
        <div class="mt-4 text-2xl text-conversion text-center uppercase">
          {{ loader.text ?? $t('Načítání...') }}
        </div>
      </div>
    </div>
  </AtomsGlobalOverlay>
</template>
<script setup>

const loader = useLoader().loader;

</script>
<style>
.lds-heart {
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.lds-heart div {
  animation: lds-heart 1s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}

@keyframes lds-heart {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}
</style>