<template>
    <div>
        <div class="quantity-field">
            <button @click="remove()" :title="$t('Odebrat')">-</button>
            <input type="number" v-model="props.modelValue" :step="props.step" @blur="update($event.target.value)" @keyup.enter="update($event.target.value)" />
            <button @click="add()" :title="$t('Přidat')">+</button>
        </div>
        <label v-if="props.unit" class="ml-3">{{ props.unit }}</label>
    </div>
</template>
<script setup>

const props = defineProps({
    modelValue: Number,
    unit: String,
    min: Number,
    max: Number,
    step: Number
})

const emit = defineEmits(['update:modelValue']);

const remove = () => {
    update(props.modelValue - (props.step ?? 1));
}

const add = () => {
    update(props.modelValue + (props.step ?? 1));
}

const update = (number) => {

    let newValue = parseInt(number);

    if (newValue < (props.min ?? 0)) {
        newValue = (props.min ?? 0);
    }

    if (typeof props.max === 'number' && newValue > props.max) {
        newValue = props.max;
    }

    if (typeof props.step === 'number' && newValue % props.step !== 0) {
        newValue = parseInt(newValue / props.step) * props.step;
    }

    emit('update:modelValue', newValue);
};

</script>
<style lang="postcss">
.quantity-field {
    display: inline-flex;
    align-items: center;
    padding: 0.2rem 0.5rem;
    @apply border-2 border-secondary;

    input[type="number"] {
        width: 3rem;
        border: 0;
        font-size: 1rem;
        text-align: center;
        -moz-appearance: textfield;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    button {
        cursor: pointer;
        font-size: 1.1rem;
        transition: all 0.15s ease-in-out;

        &:hover {
            transform: scale(1.2);
        }
    }
}
</style>