<template>
    <div class="fixed left-4 md:left-auto md:max-w-[500px] right-4 bottom-4 z-30 p-8 rounded-md bg-white shadow-around">
        <div>
            <div class="font-bold pb-4">Používáme cookies</div>
            Chceme neustále zlepšovat naše produkty a služby. K&nbsp;tomu potřebujeme váš souhlas k práci s&nbsp;cookies. Umožníte nám to? <AtomsGlobalLink to="/ochrana-osobnich-udaju">Více&nbsp;zde</AtomsGlobalLink>
        </div>
        <div v-if="showSettings" class="my-4 space-y-4">
            <label class="flex">
                <AtomsFormsCheckbox checked disabled />Funkční
            </label>
            <label class="flex">
                <AtomsFormsCheckbox :checked="cookie1" @input="cookie1 = $event.target.checked" />Preferenční
            </label>
            <label class="flex">
                <AtomsFormsCheckbox :checked="cookie2" @input="cookie2 = $event.target.checked" />Analytické
            </label>
            <label class="flex">
                <AtomsFormsCheckbox :checked="cookie3" @input="cookie3 = $event.target.checked" />Personalizované
            </label>
        </div>

        <MoleculesButtonsButton class="w-full text-center mt-8" type="conversion" @click="saveConsent(1 | 2 | 4)" v-if="!showSettings">Souhlasím</MoleculesButtonsButton>
        <div class="flex flex-wrap gap-2 pt-2">
            <MoleculesButtonsButton class="flex-1" :ghost="true" :small="true" type="conversion" @click="saveSettings()" v-if="showSettings">Souhlasím s vybranými</MoleculesButtonsButton>
            <MoleculesButtonsButton class="flex-1" :ghost="true" :small="true" type="conversion" @click="saveConsent('0')">Povolit pouze základní</MoleculesButtonsButton>
            <MoleculesButtonsButton class="flex-1" :ghost="true" :small="true" type="conversion" @click="showSettings = true" v-if="!showSettings">Nastavení</MoleculesButtonsButton>
        </div>
    </div>
</template>
<script setup>

const emit = defineEmits(['consentGranted']);

let consentValue = ref(parseInt(useShopCookie().read('cookieConsent') ?? 0));

const showSettings = ref(consentValue.value !== 0);

const cookie1 = ref((consentValue.value & 1) === 1);
const cookie2 = ref((consentValue.value & 2) === 2);
const cookie3 = ref((consentValue.value & 4) === 4);

const saveConsent = (value) => {
    consentValue.value = value;
    //useShopCookie().set('cookieConsent', consentValue.value);
    useCookie('www.snubniprsteny4u.eu-cookieConsent').value = consentValue.value;
    emit('consentGranted');
}

const saveSettings = () => {
    const value = (cookie1.value && 1) | (cookie2.value && 2) | (cookie3.value && 4);
    saveConsent(value);
}

</script>