<template>
    <div class="flex gap-4 lg:gap-8 px-6 py-8 lg:px-4 lg:py-6 lg:pl-16 lg:pr-20 mb-4 lg:items-center rounded-2xl shadow-around">
        <div class="w-16 lg:w-32 xl:w-40">
            <AtomsGlobalLink :to="props.url">
                <MoleculesGlobalWareImage v-if="props.imageId" :image-id="props.imageId" suffix="pl" />
            </AtomsGlobalLink>
        </div>
        <div class="flex flex-1 flex-wrap gap-8 lg:items-center justify-between">
            <div class="flex-1 min-w-full xl:min-w-[150px] space-y-2">
                <AtomsGlobalLink :to="props.url"><strong>{{ props.name }}</strong></AtomsGlobalLink>
                <div v-if="props.code" class="text-gray text-sm">{{ $t('Kód') }}: {{ props.code }}</div>
                <div v-if="props.variantName" class="text-gray">{{ useUtils().capitalize(props.variantName) }}</div>
                <div v-if="props.serviceInfo" class="text-gray" v-html="props.serviceInfo"></div>
                <div class="text-gray"><span class="text-primary">{{ $t('Kdy expedujeme') }}:</span> {{ props.dispatchText }}</div>
            </div>
            <div class="flex flex-grow lg:flex-grow-0 flex-wrap lg:flex-nowrap justify-between items-center gap-4 lg:gap-8 xl:pr-14 lg:min-w-[400px]">
                <MoleculesFormsQuantityField v-model="props.modelValue" @update:modelValue="update($event)" :unit="props.unit" :min="props.min" />
                <div class="hidden lg:block lg:min-w-[80px] text-right">
                    {{ props.piecePriceFormatted }}
                </div>
                <div class="min-w-[80px] font-bold xl:text-right">{{ props.totalPriceFormatted }}</div>
            </div>
        </div>
        <button v-if="!props.hideRemoveButton" class="w-6" :title="$t('Odstranit produkt z košíku')" @click="remove">
            <AtomsImagesIcon icon="zavreni" class="text-conversion" />
        </button>
    </div>
</template>
<script setup>


const locale = useLocale();

const props = defineProps({
    cartItemId: Number,
    modelValue: Number,
    imageId: Number,
    name: String,
    code: String,
    variantName: String,
    serviceInfo: String,
    description: String,
    url: String,
    dispatchText: String,
    piecePriceFormatted: String,
    totalPriceFormatted: String,
    unit: String,
    hideRemoveButton: Boolean,
    min: Number | undefined
});

const emit = defineEmits(['update:modelValue', 'remove']);

const update = (quantity) => emit('update:modelValue', quantity);

const remove = () => emit('remove');

</script>