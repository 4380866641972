<template>
  <Transition>
    <OrganismsDialogsDialog v-if="cartItem" :is-open="dialogData.show" @close="close">
      <div class="p-10">
        <h1 class="mb-8 text-3xl font-bold font-secondary">{{ $t('Zboží bylo přidáno do košíku') }}</h1>
        <MoleculesBlocksCartItem :imageId="cartItem.imageId" :name="cartItem.name" :variant-name="cartItem.variantName" :code="cartItem.code" :description="cartItem.description" :dispatchText="cartItem.dispatchText" :piecePriceFormatted="cartItem.piecePriceFormatted" :totalPriceFormatted="cartItem.customCountPriceFormatted" :hide-remove-button="true" :service-info="cartItem.serviceInfo" v-model="quantity" :min="1" @update:modelValue="(...args) => updateCartItem(cartItem.cartItemId, ...args)" />
        <div class="flex flex-wrap gap-4 items-center justify-between mt-8">
          <div>
            <button @click="close">
              <AtomsImagesIcon icon="sipkaDoleva" /> {{ $t('zpět do eshopu') }}
            </button>
          </div>
          <div>
            <MoleculesButtonsButton :arrow-right="true" :href="useAppConfig().definedPageUrls.cart" @click="dialogData.show = false">{{ $t('Přejít k nákupu') }}</MoleculesButtonsButton>
          </div>
        </div>
        <div v-if="dialogData.crossSellItems?.length">
          <AtomsTextHeader :type="3" class="header--with-line" size="text-xl lg:text-2xl ">{{ $t('Může se vám hodit') }}</AtomsTextHeader>
          <OrganismsListsWareList :items="dialogData.crossSellItems" type="slider" :withSliderPagination="true" list-name="Cross sell - přidáno do košíku"></OrganismsListsWareList>
        </div>
      </div>
    </OrganismsDialogsDialog>
  </Transition>
</template>
<script setup>

//.filter((item, index, arr) => arr.indexOf(arr.find(q => q.ware?.id === item.id)) === index)

const dialogData = useCart().addedToCartDialog;

const cartItem = computed(() => useCart().presentation.value.find(q => q.cartItemId === dialogData.value.cartItemId));
const quantity = ref(1);

const close = () => {
  useCart().addedToCartDialog.value.show = false;
}

const updateCartItem = (cartItemId, quantity) => {
  useCart().update(cartItemId, quantity - dialogData.value.addedQuantity, false, quantity);
  dialogData.value.addedQuantity = quantity;
}

</script>