<template>
    <input type="checkbox" class="checkbox" :class="{ 'cursor-pointer': !props.disabled, 'cursor-not-allowed': props.disabled }" :id="props.id" :checked="props.checked" :disabled="props.disabled" v-model="model" @change="checkChanged" />
</template>
<script setup>

const props = defineProps({
    id: String,
    checked: Boolean,
    disabled: Boolean
});

const model = ref(props.checked);

const emit = defineEmits(['change']);

const checkChanged = () => {
    emit('change', model.value);
}

</script>
<style lang="postcss">
.checkbox {
    position: relative;
    width: 0;
    height: 0;
    margin-left: 0;
    margin-right: 1.75em;
    vertical-align: middle;
    outline: 0;
    -moz-appearance: initial;

    &::before {
        position: absolute;
        content: "";
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        vertical-align: middle;
        font-size: 1rem;
        width: 1.2rem;
        height: 1.2rem;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        overflow: hidden;
        @apply border border-silver text-conversion bg-white;
    }

    &::after {
        position: absolute;
    }

    &:checked::before {
        font-style: normal;
        font-weight: normal;
        speak: never;
        font-variant: normal;
        text-transform: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "";
        @apply border-conversion;
    }

    &:checked::after {
        content: '';
        font-style: normal;
        font-weight: normal;
        speak: never;
        font-variant: normal;
        text-transform: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        transform: rotate(45deg);
        width: 0.45rem;
        height: 0.75rem;
        margin-top: 0.1rem;
        margin-left: 0.4rem;
        border-bottom-width: 3px;
        border-right-width: 3px;
        @apply border-conversion;
    }
}
</style>