<template>
    <Transition>
        <div v-if="props.show" class="fixed top-4 left-4 right-4 z-[10000] flex flex-wrap items-center justify-between gap-4 p-4 rounded-md text-white shadow-around" :class="props.type" @click="hide">
            <AtomsImagesIcon :icon="props.icon" size="3xl" v-if="props.icon" />
            <div class="flex-1 min-w-[200px]" v-html="props.text"></div>
            <MoleculesButtonsButton class="ml-auto" v-if="props.buttonText" arrow-right="true">{{ props.buttonText }}</MoleculesButtonsButton>
            <div class="flex gap-2 ml-auto" v-if="props.confirm">
                <MoleculesButtonsButton @click="result(true)">{{ $t('Ano') }}</MoleculesButtonsButton>
                <MoleculesButtonsButton @click="result(false)">{{ $t('Ne') }}</MoleculesButtonsButton>
            </div>
        </div>
    </Transition>
</template>
<script setup>

const props = defineProps({
    show: Boolean,
    icon: String,
    text: String,
    buttonText: String,
    type: String,
    confirm: Boolean
});

const emit = defineEmits(['hide', 'result']);

const hide = () => emit('hide');

const result = value => emit('result', value);

</script>